import React from "react";

import PageWrapper from "../components/PageWrapper";
import { graphql, Link } from 'gatsby'
import styled from "styled-components";
import { Box, Button, Section, Text, Title } from "../components/Core";
import bgHeroPattern from "../assets/image/webp/hero-pattern.webp";
import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";

const SectionStyled = styled(Section)`
  &::before {
    position: absolute;
    top: 0;
    content: "";
    width: 100%;
    height: 120%;
    background: url(${bgHeroPattern}) top center no-repeat;
    background-size: cover;
    z-index: -1;
  }
`;

const ImgRight = styled.img`
  border-radius: 20%;
  transition: 0.4s;
  width: 50%;
  &:hover {
    transform: scale(0.8) rotate(-16deg);
    box-shadow: 0 32px 74px rgba(68, 77, 136, 0.2);
  }
`;

const AppPage = ({ data }) => {
    const { Name, Creator, Twitter, Icon, Website, Description } = data.airtable.data;
    const imageUrl = Icon[0].thumbnails.large.url;
    return (
        <>
            <PageWrapper>
                <Helmet>
                    <title>{Name}</title>
                </Helmet>
                <SectionStyled hero className="position-relative">
                    <Container>
                        <Box className="d-flex flex-column align-items-center text-center">
                            <Box
                                className="text-center text-lg-right position-relative"
                                pb="3.125rem"
                            >
                                <div className="img-main text-center">
                                    <ImgRight src={imageUrl} alt="iPhone on table showing message Hello" className="img-fluid" />
                                </div>
                            </Box>
                            <Title>
                                {Name}
                            </Title>
                            <Title variant="card" className="mt-2">
                                by {Creator}
                            </Title>
                        </Box>
                        <Row className="justify-content-center mt-5">
                            <Col lg="8">
                                {
                                    Description.split("\n").map((item, i) => {
                                        return (
                                            <Text variant="p">
                                                {item === "" ? <br /> : item}
                                            </Text>
                                        )
                                    })
                                }
                            </Col>
                        </Row>
                        <Row className="justify-content-center text-center mt-5">
                            <Col lg="4" xs="6">
                                <Link to={Website}>
                                    <Button arrowRight>Website</Button>
                                </Link>
                            </Col>
                            {Twitter && <Col lg="4" xs="6">
                                <Link to={"https://twitter.com/" + Twitter}>
                                    <Button arrowRight style={{
                                        background: "#1DA1F2",
                                    }}>Twitter</Button>
                                </Link>
                            </Col>}
                        </Row>
                    </Container>
                </SectionStyled>
            </PageWrapper>
        </>
    );
};

export const query = graphql`
    query ($slug: String!) {
        airtable(recordId: {
            eq: $slug
        }) {
            data {
                Description
                Icon {
                    url
                    thumbnails {
                        large {
                            url
                        }
                    }
                }
                Creator
                Name
                Website
                Twitter
            }
        }
    }
`

export default AppPage;
